"use client";

import { signIn } from "next-auth/react";
import React from "react";

type SignInButtonProps = {
  id: string;
  callbackUrl: string;
  children: React.ReactNode;
  className: string;
};

const SignInButton: React.FC<SignInButtonProps> = ({ id, callbackUrl, className, children }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const onSignIn = async () => {
    setLoading(true);
    try {
      await signIn(id, { callbackUrl });
    } catch (e) {
      setLoading(false);
      // @ts-ignore
      setError(e?.message || "An error occurred");
    }
  };
  return (
    <>
      {error && (
        <div role="alert" className="alert alert-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Error! {error} </span>
        </div>
      )}
      <button onClick={onSignIn} className={className}>
        {loading ? <span className="loading loading-spinner loading-sm" /> : children}
      </button>
    </>
  );
};

export default SignInButton;
