"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { signIn } from "next-auth/react";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type MagicLinkFormProps = {
  id: string;
  callbackUrl: string;
  buttonTitle: string;
  buttonClass: string;
  inputClass: string;
  placeholder?: string;
};

const formSchema = z.object({
  email: z.string().email("Invalid email address"),
});

export type FormValues = z.infer<typeof formSchema>;

const MagicLinkForm: React.FC<MagicLinkFormProps> = ({
  id,
  callbackUrl,
  buttonTitle,
  buttonClass,
  inputClass,
  placeholder = "Email address",
}) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    try {
      await signIn(id, { email: data.email, callbackUrl });
    } catch (e) {
      setLoading(false);
      // @ts-ignore
      setError(e?.message || "An error occurred");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register("email")} placeholder={placeholder} className={inputClass} />
        {(errors?.email || error) && <p>{errors?.email?.message || error}</p>}
        <button className={buttonClass} disabled={loading}>
          {loading ? (
            <span className="loading loading-spinner loading-sm" />
          ) : (
            <>
              <svg
                className="-ml-2 size-6"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                <circle cx="8.5" cy="7" r="4" />
                <path d="M20 8v6M23 11h-6" />
              </svg>
              <span className="ml-3">{buttonTitle}</span>
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default MagicLinkForm;
